@use "sass/core/variables";
@use "sass/core/utils";

$main-menu-width: 86px;
$main-menu-item-height: 76px;
$main-menu-bottom-item-height: 50px;
$main-menu-item-text-height: 22px;
$main-menu-item-icon-padding: 2px;
$main-menu-item-icon-margin: 4px;

.mainMenu {
    // Fix weird visual bug causing the transparent not getting through
    .md-button:not([disabled]):focus {
        background-color: transparent;
    }

    .menu-bottom {
        margin-bottom: variables.$default-spacing * 0.5;
    }

    &.fullscreen {
        max-width: $main-menu-width;

        .horizontalAlignCenter {
            text-align: center;
            width: 100%;
        }

        .menu-bottom {
            md-list-item {
                height: $main-menu-bottom-item-height;
            }
        }

        md-list-item {
            height: $main-menu-item-height;

            & > div.md-button:first-child {
                padding: 0;
            }

            .md-ripple-container {
                display: none;
            }

            .md-button:hover,
            .md-button:focus {
                background-color: transparent;
            }

            md-icon {
                padding-top: $main-menu-item-icon-padding;
                padding-bottom: $main-menu-item-icon-padding;
                margin-top: $main-menu-item-icon-margin;
                margin-bottom: $main-menu-item-icon-margin;
            }

            p {
                @include utils.font-size(12, true);

                color: variables.$soft-white-color !important;
            }

            md-icon:not(.icon-logo):before {
                color: variables.$soft-white-color !important;
            }

            md-icon.icon-logo:before {
                color: variables.$salesflare-light-color !important;
            }

            &.active {
                md-icon:not(.icon-logo) {
                    width: 36px;
                    background-color: #3c4253;
                    border-radius: 4px;
                }

                md-icon.icon-logo:before {
                    color: variables.$salesflare-light-color !important;
                }
            }

            &:hover {
                md-icon:not(.icon-logo) {
                    width: 36px;
                    background-color: #3c4253;
                    border-radius: 4px;
                }
            }
        }
    }

    // On mobile make sure that the main menu covers any open walkthrough tooltips
    // These walkthrough tooltips have a z-index of 81 so they don't get buried by the backdrops of dialogs
    // For the same reason we don't want this rule on fullscreen since it would display the main menu over backdrops, on mobile this is not a problem
    &:not(.fullscreen) {
        z-index: 82;

        md-list-item {
            p {
                color: variables.$sidenav-color !important;
            }
        }
    }

    // md-icon:not(.sf-icon-settings-notifications) {
    md-icon {
        &:before {
            color: variables.$dark-gray-color;
        }

        &.sf-icon-settings-notifications.has-notifications:before {
            color: variables.$white-color;
        }

        &.sf-icon-send {
            padding-right: 0;
        }
    }

    p {
        line-height: $main-menu-item-text-height;
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        p {
            /* quick fix, should be replaced with font-size after deleting more global rule and checking nothing else is impacted */
            font-size: 12px !important;
        }
    }
}
