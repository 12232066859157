// core
@use "core/variables";
@use "core/utils";
@use "core/animations";
@use "core/icon-extensions";
@use "core/icons";

// HTML element styling
@use "components/html-elements/a";
@use "components/html-elements/form";
@use "components/html-elements/scrollbar";

// angularjs-material fixes
@use "components/angularjs-material/fab-menu";
@use "components/angularjs-material/md-autocomplete";
@use "components/angularjs-material/md-button";
@use "components/angularjs-material/md-bottom-sheet";
@use "components/angularjs-material/md-card";
@use "components/angularjs-material/md-checkbox";
@use "components/angularjs-material/md-chip";
@use "components/angularjs-material/md-datepicker";
@use "components/angularjs-material/md-dialog";
@use "components/angularjs-material/md-divider";
@use "components/angularjs-material/md-icon";
@use "components/angularjs-material/md-input-container";
@use "components/angularjs-material/md-list";
@use "components/angularjs-material/md-menu";
@use "components/angularjs-material/md-progress-circular";
@use "components/angularjs-material/md-radio-button";
@use "components/angularjs-material/md-select";
@use "components/angularjs-material/md-sidenav";
@use "components/angularjs-material/md-subheader";
@use "components/angularjs-material/md-switch";
@use "components/angularjs-material/md-tabs";
@use "components/angularjs-material/md-toast";
@use "components/angularjs-material/md-toolbar";
@use "components/angularjs-material/md-tooltip";

// dependencies
@use "dependencies/cssplot.full"; /* stylelint-disable-line scss/at-import-partial-extension */
@use "dependencies/css-plot";
@use "dependencies/icon-font";
@use "dependencies/jodit";
@use "dependencies/loading-bar";
@use "dependencies/minicolors";
@use "dependencies/ng-messages";
@use "dependencies/ng-sortable";
@use "dependencies/shepherd";
@use "dependencies/swipe";
@use "dependencies/textcomplete";
@use "dependencies/ui-cropper";

// shared
@use "components/salesflare/workflow-shared/workflow-config";

// own components
@use "components/salesflare/predefined-reports/common";
@use "components/salesflare/predefined-reports/sf-activity-per-rep";
@use "components/salesflare/predefined-reports/sf-closing-percentage";
@use "components/salesflare/predefined-reports/sf-closing-percentage-per-rep";
@use "components/salesflare/predefined-reports/sf-funnel-analysis";
@use "components/salesflare/predefined-reports/sf-mrr";
@use "components/salesflare/predefined-reports/sf-quota-per-rep";
@use "components/salesflare/predefined-reports/sf-revenue-vs-goal";
@use "components/salesflare/predefined-reports/sf-revenue-vs-previous-period";
@use "components/salesflare/predefined-reports/sf-slipping-deals";
@use "components/salesflare/predefined-reports/sf-top-earning-accounts";
@use "components/salesflare/predefined-reports/sf-top-lead-sources";
@use "components/salesflare/predefined-reports/sf-top-lost-reasons";
@use "components/salesflare/date-time-panel";
@use "components/salesflare/setup-panel";
@use "components/salesflare/sf-addresses";
@use "components/salesflare/sf-avatar";
@use "components/salesflare/sf-assign-task-panel";
@use "components/salesflare/sf-badge";
@use "components/salesflare/sf-card";
@use "components/salesflare/sf-chart";
@use "components/salesflare/sf-chart-builder";
@use "components/salesflare/sf-contextual-help-dialog";
@use "components/salesflare/sf-credit-package-confirmation-dialog";
@use "components/salesflare/sf-bulk-toolbar";
@use "components/salesflare/sf-dashboard";
@use "components/salesflare/sf-dashboards";
@use "components/salesflare/sf-dashboards-filter";
@use "components/salesflare/sf-discard-dialog";
@use "components/salesflare/sf-downgrade-confirmation-dialog";
@use "components/salesflare/sf-edit-card-dialog";
@use "components/salesflare/sf-email-display";
@use "components/salesflare/sf-empty-state";
@use "components/salesflare/sf-entity-counts";
@use "components/salesflare/sf-entity-filters";
@use "components/salesflare/sf-exit-workflow-record-button";
@use "components/salesflare/sf-export-fields-dialog";
@use "components/salesflare/sf-fake-sticky";
@use "components/salesflare/sf-file-upload";
@use "components/salesflare/sf-files-customfield";
@use "components/salesflare/sf-form-error-indicator";
@use "components/salesflare/sf-import";
@use "components/salesflare/sf-import-list";
@use "components/salesflare/sf-import-start";
@use "components/salesflare/sf-import-upload";
@use "components/salesflare/sf-import-map";
@use "components/salesflare/sf-intro";
@use "components/salesflare/sf-join-existing-team-dialog";
@use "components/salesflare/sf-lead-hotness";
@use "components/salesflare/sf-list-item-info";
@use "components/salesflare/sf-main-menu";
@use "components/salesflare/sf-manage-saved-filters-dialog";
@use "components/salesflare/sf-md-chips-container";
@use "components/salesflare/sf-merge-accounts-dialog";
@use "components/salesflare/sf-message-box";
@use "components/salesflare/sf-notification-panel";
@use "components/salesflare/sf-onboarding-questions";
@use "components/salesflare/sf-opportunity";
@use "components/salesflare/sf-opportunity-info";
@use "components/salesflare/sf-password-meter";
@use "components/salesflare/sf-permissions";
@use "components/salesflare/sf-pivot-table";
@use "components/salesflare/sf-plan-flag";
@use "components/salesflare/sf-plans";
@use "components/salesflare/sf-power-trial-alert-content";
@use "components/salesflare/sf-pricing-switch";
@use "components/salesflare/sf-reorder-dashboards-dialog";
@use "components/salesflare/sf-report-edit";
@use "components/salesflare/sf-report-card";
@use "components/salesflare/sf-report-view";
@use "components/salesflare/sf-save-filter-dialog";
@use "components/salesflare/sf-scorecard";
@use "components/salesflare/sf-send-test-email-config-dialog";
@use "components/salesflare/sf-social-feed";
@use "components/salesflare/sf-stage-updates";
@use "components/salesflare/sf-status-bar";
@use "components/salesflare/sf-switch-button";
@use "components/salesflare/sf-talk-to-us-info";
@use "components/salesflare/sf-task-create";
@use "components/salesflare/sf-task-settings";
@use "components/salesflare/sf-task";
@use "components/salesflare/sf-tasks-account";
@use "components/salesflare/sf-tasks-completed";
@use "components/salesflare/sf-tasks-today";
@use "components/salesflare/sf-tasks-upcoming";
@use "components/salesflare/sf-tasks";
@use "components/salesflare/sf-top-toolbar";
@use "components/salesflare/sf-upgrade-confirmation-dialog";
@use "components/salesflare/sf-upgrade-dialog";
@use "components/salesflare/sf-view-credit-usage-dialog";
@use "components/salesflare/sf-workflows";
@use "components/salesflare/sf-workflows-overview-dialog";
@use "components/salesflare/sf-workflow-analytics";
@use "components/salesflare/sf-workflow-card";
@use "components/salesflare/sf-workflow-details";
@use "components/salesflare/sf-workflow-step-action";
@use "components/salesflare/sf-workflow-step-analytics";
@use "components/salesflare/sf-workflow-step";
@use "components/salesflare/sf-you-can-book-me-form";
@use "components/salesflare/template-manager-dialog";
@use "components/salesflare/template-selection-panel";

// views
@use "views/account";
@use "views/account-info";
@use "views/accounts";
@use "views/api-key-settings";
@use "views/application-settings";
@use "views/assign-dialog";
@use "views/billing-settings-history";
@use "views/billing-settings-overview";
@use "views/checkout";
@use "views/company";
@use "views/conference";
@use "views/contacts";
@use "views/choose-audience";
@use "views/credits";
@use "views/customfields";
@use "views/customfields-settings";
@use "views/deals";
@use "views/delete-contact-dialog";
@use "views/delete-import-dialog";
@use "views/edit-contacts";
@use "views/edit-person";
@use "views/edit-picture-dialog";
@use "views/edit-signature-dialog";
@use "views/edit-stage-dialog";
@use "views/edit-task";
@use "views/edit-user";
@use "views/email-compose-dialog";
@use "views/email-tracking-dialog";
@use "views/feed";
@use "views/files";
@use "views/insights";
@use "views/insights-revenue";
@use "views/insights-team";
@use "views/invite-dialog";
@use "views/login-signup";
@use "views/meeting";
@use "views/notification-settings";
@use "views/online-offline-dialog";
@use "views/opportunities";
@use "views/person-info";
@use "views/pipeline-settings";
@use "views/plans";
@use "views/regional-settings";
@use "views/reminder";
@use "views/self-select";
@use "views/settings";
@use "views/select-account-dialog";
@use "views/support";
@use "views/suggested-accounts";
@use "views/targets";
@use "views/team-settings";
@use "views/tracking-script-dialog";
@use "views/user-benefits-dialog";
@use "views/restricted-dialog";
@use "views/subscribe-dialog";
@use "views/extend-trial-dialog";
@use "views/walkthrough";
@use "views/workflow-compose-dialog";

// Temporary fix for Chrome 48 flex bug
.flex,
md-list-item.md-2-line .md-list-item-text,
md-list-item.md-2-line > .md-no-style .md-list-item-text,
md-list-item.md-3-line .md-list-item-text,
md-list-item.md-3-line > .md-no-style .md-list-item-text {
    min-width: 0;
    min-height: 0;
}

body {
    overflow: hidden;
}

body.print {
    overflow: initial;
    print-color-adjust: exact;
    width: 210mm;
    height: 297mm;

    * {
        max-width: initial;
        animation: none !important;
    }

    @page {
        size: a4;
        margin: 0;
    }
}

html,
body,
md-content,
md-dialog {
    background-color: variables.$bg-color;
    font-family: variables.$default-font;
    color: variables.$text-color;
    font-size: variables.$font-size-14 + px;
}

button,
select,
textarea,
input {
    font-family: variables.$default-font;
}

.bg-white,
.bg-white md-content {
    background-color: variables.$white-color;
}

.bg-gray {
    background-color: variables.$list-bg-color;
}

a {
    text-decoration: none;
    color: variables.$blue-color;

    &.click {
        cursor: pointer;
    }
}

h1 {
    @include utils.font-size(24);

    font-weight: 400;
}

/* fix yellow background and black font color on pre-filled form fields */
.bg-white input:-webkit-autofill,
.bg-white textarea:-webkit-autofill,
.bg-white select:-webkit-autofill {
    box-shadow: 0 0 0 1000px variables.$gray-color inset !important;
    -webkit-text-fill-color: variables.$reset-text-color !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    box-shadow: 0 0 0 1000px variables.$gray-color inset !important;
    -webkit-text-fill-color: variables.$reset-text-color !important;
}

#index {
    overflow-x: hidden;

    // iPhone X specific fixes
    padding-bottom: env(safe-area-inset-bottom);
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

#index.print {
    overflow-x: initial;
}

.action-menu-dots {
    color: variables.$text-color;
}

.md-tile-content {
    padding: 0;
}

input::-ms-clear {
    @include utils.hide;
}

#chips-list {
    z-index: 1000;
}

.boxclose {
    margin-top: -16px;
}

textarea.script {
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    word-wrap: initial;
    white-space: pre;
}

/* fixes for email layout */
div.email {
    overflow: auto;
}

/* for pure text email */
pre {
    /* CSS 3 */
    white-space: pre-wrap;

    /* Mozilla, since 1999 */
    white-space: -moz-pre-wrap;

    /* Opera 4-6 */
    white-space: -pre-wrap;

    /* Opera 7 */
    white-space: -o-pre-wrap;

    /* Internet Explorer 5.5+ */
    word-wrap: break-word;
}

small {
    color: variables.$secondary-color;
}

/* end fixes for email layout */
$only-ie8-and-above-screen: screen\0;

@media #{$only-ie8-and-above-screen} {
    .login-input input {
        padding-bottom: 7px !important;
    }
}

select {
    padding-right: 20px;
    border-width: 0 0 1px;
    color: variables.$select-color;
    border-color: variables.$select-border-color;
    appearance: none;
    background: url("../images/arrow_down_black.png") no-repeat right white;
}

select::-ms-expand {
    @include utils.hide;
}

blockquote {
    @include utils.hide;
}

:focus {
    outline: none;
}

.button {
    outline: none;
    cursor: pointer;
}

.selected {
    background-color: variables.$highlight-color;
}

.bulk-selected {
    background-color: variables.$bulk-select-color;
}

// These are reset functions used globally
// That is the only reason we use !important here
.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-border {
    border: none !important;
    border-radius: unset;
}

.toolbar-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 48px;
    margin: 0;
    padding: 0;
}

.toolbar-button {
    text-transform: uppercase;
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: variables.$font-size-16 + px;
    border-radius: 0 !important;
    cursor: pointer;
}

.toolbar-button:hover {
    background-color: transparent !important;
}

.toolbar-button span {
    margin: auto;
}

.toolbar-button[disabled] {
    color: variables.$disabled-color;
    background-color: variables.$disabled-bg-color !important;
    cursor: default;
}

.toolbar-button-primary {
    color: variables.$white-color;
    background-color: variables.$toolbar-primary-bg-color !important;
}

.toolbar-button-primary[disabled] {
    color: variables.$disabled-color;
    background-color: variables.$disabled-bg-color !important;
    cursor: default;
}

.btn-load-more {
    border-radius: 0;
    width: 100%;
    background: variables.$load-more-color;
}

.accent {
    color: variables.$accent-color;
}

.semi-accent {
    color: variables.$semi-accent-color;
}

.secondary {
    color: variables.$secondary-color;
}

.text-color {
    color: variables.$text-color;
}

.warning {
    color: variables.$warning-color;
}

.underline {
    text-decoration: underline;
}

.bold {
    font-weight: bold;
}

.strikethrough {
    position: relative;
}

.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 49%;
    right: 0;
    border-top: 2px solid;
    border-color: inherit;
    transform: rotate(-10deg);
}

.strikethrough_1 {
    position: relative;
}

.strikethrough_1:before {
    position: absolute;
    content: "";
    left: 0;
    top: 49%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    transform: rotate(-10deg);
}

/* Material fixes */

.md-contact-suggestion img {
    width: 40px;
}

i[sf-clipboard-text],
i[ng-click],
i[ui-sref],
md-list-item[ng-click],
md-list-item[ui-sref],
md-item-content[ng-click],
md-item-content[ui-sref],
md-card-content[ng-click],
md-card-content[ui-sref],
md-tab-item[ng-click],
md-tab-item[ui-sref],
div[ng-click],
div[ui-sref],
button,
.pointer {
    -webkit-touch-callout: none;
    user-select: none;
    cursor: pointer;
}

md-progress-linear .md-container.md-ready {
    height: 2px;
}

.md-send {
    cursor: pointer;
}

.attachment {
    background: variables.$disabled-bg-color;
    color: variables.$black-color !important;
    text-decoration: none !important;
    display: inline-block;
    padding: 10px;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
}

.footer {
    position: relative;
    border-radius: 24px;
    margin: 16px;
    padding-top: 13px;
    padding-bottom: 13px;
    min-height: 47px; // 42 from textarea + paddings
    max-height: 200px;
    background-color: #f1f6ff;

    textarea {
        resize: none;
        border: none;
    }

    // fab speed dial for meetings
    .fab-menu {
        bottom: 0; // make sure it doesn't drop down

        .md-button.md-fab {
            margin: 7px 8px; // make it fit like a glove
        }
    }
}

/* added to remove all the style of the button */
.no-button-style {
    outline: none;
    background-color: transparent;
    border: none;
    color: variables.$white-color;
    margin: 0;
    padding: 0;
}

.background-white {
    background-color: variables.$white-color;
}

[ng-drag].dragging {
    opacity: 0;
}

.line-height-24 {
    line-height: 24px;
}

.line-height-36 {
    line-height: 36px;
}

.contact-domain {
    color: variables.$text-color;
    margin-left: 4px;
}

.multiline-link {
    word-break: break-all;
}

.network-list {
    margin-bottom: 48px;
}

.part-of {
    margin-right: 4px;
}

.time-input {
    width: 55px;

    .time-input-hours {
        margin-right: 3px;
    }
}

.time-input-panel {
    width: 72px;
}

.time-input-divider {
    margin-top: 17px;
    font-weight: bold;
}

.dialog-checkbox {
    margin-top: 9px;
}

.text-align_center {
    text-align: center;
}

.text-align_right {
    text-align: right;
}

.text-align_left {
    text-align: left;
}

.font-weight_normal {
    font-weight: normal;
}

// make sure the box has the placeholder
[contentEditable="true"]:empty:before {
    content: attr(placeholder);
    color: variables.$secondary-color;
}

.white-text {
    color: variables.$white-color;
}

.align-end {
    text-align: end;
}

.grab {
    cursor: grab;
}

.full-width {
    width: 100%;
}

.visibility_hidden {
    visibility: hidden;
}

.overflow-x_hidden {
    overflow-x: hidden;
}

.translucent {
    opacity: 0;
}

.translucent-25 {
    opacity: 0.25;
}

.translucent-50 {
    opacity: 0.5;
}

.translucent-1 {
    opacity: 1;
}

.gray-out {
    filter: grayscale(1);
    opacity: 0.7;
}

.new-count-container {
    display: inline-block;
    position: relative;

    .new-count-badge {
        font-family: variables.$default-font;
        background-color: variables.$red-color;
        border-radius: 2px;
        color: variables.$white-color;
        padding: 3px 3px 2px;
        font-size: 10px;
        position: absolute;
        top: -4px;
        right: -8px;
    }

    &.sf-icon-salesflare > .new-count-badge {
        top: 11px;
        right: 0;
    }

    &.sf-icon-settings-notifications > .new-count-badge {
        right: -25px;
    }
}

.filter-applied-indicator-container,
.sort-applied-indicator-container,
.new-feature-container {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.new-feature-container {
    // This is needed when the container is used inside clickable elements like an md-list
    // The position: relative causes it to capture the pointer events causing the button to no longer be clickable
    pointer-events: none;
}

.filter-applied-indicator,
.sort-applied-indicator,
.new-feature-indicator {
    border-radius: 50%;
    position: absolute;
    height: 8px;
    width: 8px;
}

.filter-applied-indicator,
.sort-applied-indicator {
    background-color: variables.$orange-color;
    top: 2px;
}

.new-feature-indicator {
    background-color: variables.$red-color;
    top: 0;
}

.filter-applied-indicator {
    right: 0;
}

.sort-applied-indicator {
    right: -2px;
}

.customfieldsInfo {
    user-select: text;
    margin-right: 16px;
}

.lity {
    background: rgb(0 0 0 / 70%);
}

.font-size-12 {
    @include utils.font-size(variables.$font-size-12);
}

.dropdown-menu.textcomplete-dropdown {
    max-height: 200px;
    overflow-y: auto;
    max-width: 400px;
    width: calc(100vw - 16px);
}

.feed-filter_content,
.feed-filter_sub_content,
.md-menu-dropdown {
    .md-button,
    md-icon,
    md-icon[class*="sf-icon"],
    md-icon[class^="sf-icon"] {
        color: variables.$accent-color;
    }
}

// We don't want the recaptcha badge to show.
// We do need to add something in the places we use it, though: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge { visibility: hidden; }

// Recaptcha shows errors in divs without a specific class which makes it hard to select them
// We try to select them as specific as possible
// See https://github.com/Salesflare/Server/issues/6749 for more details
body > div:not([class]) > div:not([class]):only-child {
    display: none;
}

// Chrome and Edge don't fire mouse events on disabled buttons
// We wrap the disabled button in a div so we catch the mouse events to show the tooltip
// https://github.com/angular/material/issues/8530#issuecomment-394957301
.tooltip-disabled-button-wrapper {
    display: inline-block;

    button[disabled] {
        // Don't let the button block mouse events from reaching wrapper
        pointer-events: none;
    }
}

.sidebar-tooltip {
    max-width: 280px; // Limit to +- the width of the sidebar
    white-space: pre-line; // Show new lines
}

.md-button.skip-walkthrough-button {
    text-transform: none;
    font-weight: 800;
    letter-spacing: normal;
    position: absolute;
    width: fit-content;
    left: calc(98px + 1rem);
    bottom: 1rem;
    z-index: 70;
    padding: 2px 12px;

    &:not([disabled]) {
        background-color: variables.$blue-color;
        color: variables.$white-color;
    }

    &.md-default-theme.md-raised:not([disabled]):hover,
    &.md-raised:not([disabled]):hover {
        background-color: variables.$blue-color;
    }
}

.click-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.blue {
    color: variables.$blue-color;
}
