@use "sass/core/variables";
@use "sass/core/utils";

.md-chips {
    font-family: "Source Sans 3", sans-serif;
}

md-chip,
%md-chip {
    @include utils.font-size(13);

    background-color: variables.$gray-highlight-color;
    color: variables.$salesflare-color;
    cursor: default;
    border-radius: 8px !important;
    display: block;
    height: 32px;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0 12px;
    float: left;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;

    md-icon {
        min-height: 18px;
        min-width: 18px;
        height: 18px;
        width: 18px;
        fill: variables.$text-color;
    }

    &.sf-chip-error {
        background: rgb(255 176 176 / 50%);
    }
}

md-chip.md-focused {
    color: variables.$salesflare-color;
    background-color: variables.$gray-color;
}

md-chips {
    .md-chips md-chip,
    .md-chip {
        @extend %md-chip;
    }

    .md-chips {
        padding-bottom: 0;
        margin-bottom: 0;

        .md-chip-input-container {
            width: 100%;
        }
    }

    &:not(.without-search-icon) {
        .md-chips {
            .md-chip-input-container {
                width: 100%;
                background-image: url("../../../images/sf-icon-search.png");
                background-repeat: no-repeat;
                background-position-x: 2px;
                background-position-y: 8px;

                md-autocomplete {
                    padding-left: 23px;
                }
            }
        }
    }
}

.contact-chip-picture,
.entity-chip-picture {
    float: left;

    img {
        height: 32px;
        border-radius: 16px;
    }
}

.contact-chip-name,
.entity-chip-name {
    display: inline-block;
    height: 32px;
    margin-left: 8px;
}

.contact-chip md-chips-wrap md-chip,
.entity-chip md-chips-wrap md-chip {
    padding-left: 0;
}

// fix md-contact-chips not passing on readonly attribute so we have to make it readonly ourselves
md-contact-chips[disabled] {
    .md-chips:not(.md-readonly) .md-chip:not(.md-readonly) {
        padding: 0 10px 0 0;
    }

    md-chips-wrap {
        box-shadow: 0 0;
    }

    .md-chip-input-container,
    .md-chip-remove-container {
        @include utils.hide;
    }
}

md-contact-chips {
    md-chips {
        .md-chips {
            margin-bottom: 0;
        }
    }
}

.sf-md-chips-container {
    .relative_label {
        position: relative;
    }
}
