@use "sass/core/variables";

.md-sidenav-right.filters {
    width: 100%;

    // overlap the FAB
    z-index: 71;
}

.entity-filter,
.entity-filter * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    box-shadow: none;
}

.entity-filter {
    md-toolbar .md-toolbar-tools {
        md-icon.button {
            margin-left: 16px;
        }
    }

    .filter-header {
        margin: variables.$default-spacing * 0.5 0 variables.$default-spacing * 0.5 variables.$default-spacing * 0.5;
        color: variables.$salesflare-color;

        &.entity-filter_bad-filter-info {
            padding: 0 variables.$default-spacing;

            .entity-filter_bad-filter-info_info-icon {
                color: variables.$red-color;
            }

            .entity-filter_bad-filter-info_info-text {
                margin-left: variables.$default-spacing * 0.5;
                margin-bottom: variables.$default-spacing;
            }
        }
    }

    .filter-actions {
        margin-bottom: 30px;
        display: flex;
        justify-content: flex-end;

        .filter-btn {
            padding: 0 5px;
            margin-right: 10px;
            margin-top: -44px;
            max-height: 36px;
        }
    }

    .rules-group-container {
        position: relative;
        padding: 0  variables.$default-spacing * 0.5 variables.$default-spacing * 0.5 variables.$default-spacing * 0.5;
        background: variables.$gray-color;

        #add-rule-btn {
            padding: 0 5px;

            #icon-add-rule {
                margin-bottom: 3px;
                color: variables.$blue-color;
            }
        }

        #load-filter-btn {
            padding: 0 5px;

            #icon-load-filter {
                margin-bottom: 2px;
                color: variables.$blue-color;
            }
        }
    }

    .saved-filters {
        .saved-filters-select-container {
            position: relative;
            background: variables.$white-color;
            padding: variables.$default-spacing;
            border-radius: 5px;
            margin: 0 variables.$default-spacing * 0.5;
        }

        .saved-filter-empty-state {
            padding: 0 variables.$default-spacing 0 24px;
        }

        .manage-saved-filters {
            max-height: 36px;
            padding: 0 5px;
            margin-right: 10px;
        }

        .saved-filter-actions {
            justify-content: flex-end;
            padding: 0 variables.$default-spacing * 0.5;

            button {
                padding: 0 5px;
            }
        }
    }

    .rule-container {
        position: relative;
        background: variables.$white-color;
        padding: variables.$default-spacing;
        border-radius: 5px;
        margin: variables.$default-spacing * 0.5 0;

        &.first {
            margin-top: 0;
        }

        &.disabled,
        &.no-edit {
            .rule-header :not(md-icon) {
                opacity: 0.35;
            }

            .rule-content {
                opacity: 0.35;
            }
        }

        &.disabled {
            background: rgb(255 176 176 / 50%);
        }

        &.no-edit {
            background: rgb(219 219 219);
        }

        // Drop down for rule-container
        .md-filter-name {
            margin-left: 10px;
        }

        .rule-header {
            .search-input {
                background-image: url("../../../images/sf-icon-search.png");
                background-repeat: no-repeat;
                background-position-x: 2px;
                background-position-y: 8px;

                md-autocomplete-wrap {
                    background-image: url("../../../images/arrow_down_gray.png");
                    background-repeat: no-repeat;
                    background-position-x: right;
                    background-position-y: 5px;

                    input {
                        text-indent: 20px;
                    }
                }

                button {
                    display: none;
                }
            }

            md-input-container {
                width: 100%;
                min-width: 0;
                margin-right: 12px;
            }

            h3 {
                font-weight: 400;
                font-size: 16px;
                margin-right: auto;
            }

            md-icon {
                margin-left: auto;
                margin-top: 5px;
                color: variables.$blue-color;
            }
        }

        md-divider {
            border-top: 1px solid variables.$light-gray-color;
            bottom: 0;
        }

        md-divider.last-element {
            border-top: 1px solid variables.$light-gray-color;
            bottom: 0;
            display: block;
        }

        sf-filters-operator {
            width: 100%;

            .operator-value-container {
                width: 100%;
                display: flex;
                flex-direction: row;

                .rule-operator-container {
                    margin-right: 20px;
                    align-self: flex-end;

                    md-select {
                        margin-top: 15px;
                    }
                }

                .rule-value-container {
                    width: 100%;
                    align-self: flex-end;

                    md-select-value {
                        span {
                            &:not(.md-select-icon) {
                                text-overflow: ellipsis;
                                padding-right: 20px;
                            }
                        }

                        .md-select-icon {
                            position: absolute;
                            right: 10px;
                        }
                    }

                    md-input-container {
                        .regular-input {
                            input {
                                margin-bottom: -24px;
                            }
                        }

                        .select-input {
                            .md-select-value {
                                span {
                                    overflow: hidden;
                                    position: absolute;
                                }
                            }
                        }

                        .select-input.not_in {
                            span:first-child {
                                visibility: collapse;
                            }

                            .md-text {
                                display: inline-block;
                                visibility: visible;
                            }

                            .md-text:not(:last-child):after {
                                content: "NOR";
                                margin: 0 0 0 5px;
                            }
                        }

                        .select-input.in {
                            span:first-child {
                                visibility: collapse;
                            }

                            .md-text {
                                display: inline-block;
                                visibility: visible;
                            }

                            .md-text:not(:last-child):after {
                                content: "OR";
                                margin: 0 0 0 5px;
                            }
                        }

                        &.date-picker {
                            margin-bottom: -24px;

                            input {
                                border-bottom-width: 0;
                            }
                        }

                        .auto-complete-input {
                            margin-bottom: 1px;

                            .contact-chip-name {
                                margin-left: 8px;
                            }
                        }
                    }

                    md-chips.in {
                        md-chip:not(:last-of-type):after {
                            content: "OR";
                            position: absolute;
                            right: -22px;
                        }

                        md-chip {
                            margin-right: 25px;
                        }
                    }

                    md-chips.not_in {
                        md-chip:not(:last-of-type):after {
                            content: "NOR";
                            position: absolute;
                            right: -30px;
                        }

                        md-chip {
                            margin-right: 32px;
                        }
                    }
                }
            }
        }
    }
}

#propertyFilterForm {
    sf-filters-operator {
        md-input-container {
            &.date-picker {
                height: 31px;
            }
        }
    }
}
