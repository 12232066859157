@use "sass:math";
@use "sass/core/variables";
@use "sass/core/utils";

#email-compose {
    position: relative; // needed to correctly position the drag overlay
    background-color: variables.$gray-color;

    md-content {
        overflow: inherit;
    }

    form {
        padding: 0;
    }

    .compose-side-margin {
        margin-left: variables.$default-spacing;
        margin-right: variables.$default-spacing;
    }

    .spinner {
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 4px;
    }

    .extra-options-container {
        padding-bottom: variables.$default-spacing * 0.5;

        md-checkbox.footer-checkbox {
            margin: 0 (variables.$default-spacing * 0.5) 0 variables.$default-spacing;

            .md-label {
                color: variables.$black-color; // make text and icons are black
            }
        }

        md-icon {
            color: variables.$black-color; // make text and icons are blacks
        }
    }

    .preview {
        overflow-y: auto;
        padding: variables.$default-spacing variables.$default-spacing 0 variables.$default-spacing;

        md-chip md-icon:not(.sf-icon-close) {
            margin-right: variables.$default-spacing * 0.25;
        }
    }

    md-switch.campaign-form {
        height: 24px;
        padding-bottom: variables.$default-spacing * 0.5;
        border-bottom: 1px solid;
        border-color: rgb(0 0 0 / 12%);
        color: rgb(0 0 0 / 38%);

        &.invalid {
            border-color: variables.$error-color;
        }
    }

    .audience-section {
        padding: 0;
        height: 38px;
        border-bottom: 1px solid;
        border-color: rgb(0 0 0 / 12%);

        &.no-border {
            border-bottom: none;
        }

        .label {
            margin: auto (variables.$default-spacing * 0.5) auto 0;
            color: rgb(0 0 0 / 38%);
        }

        .choose-audience-link-container {
            margin: auto 0;
        }
    }

    md-input-container {
        margin: 0;
        padding: 0;

        &.sf-md-chips-container.campaign-form {
            .label {
                margin-top: 10px;
                margin-bottom: auto;
            }

            md-chips {
                cursor: text;
                box-shadow: 0 1px rgb(0 0 0 / 12%);
            }

            md-chips-wrap {
                margin-top: 6px;

                // setting height to 0 and moving the box-shadow to the parent somehow fixes issue with the autocomplete not focusing
                height: 0;
                box-shadow: none;
            }

            md-chip {
                margin: 2px 0;
                padding: 0 variables.$default-spacing 0 (variables.$default-spacing * 0.5);
                line-height: 20px;
                height: 20px;
                font-size: 12px;

                button.md-chip-remove {
                    height: 20px;
                    width: 12px;
                    margin-right: 4px;

                    md-icon {
                        min-height: 12px;
                        min-width: 12px;
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }

        .md-chip-input-container {
            margin-top: -6px;
        }
    }

    md-checkbox.campaign-form {
        .md-label {
            margin-top: 2px;
        }
    }

    .campaign-form {
        padding: 0;
        margin: variables.$default-spacing 0;

        .campaign-form-select {
            margin: 0;

            // Double pound showing on invalid data source
            md-select-value > span > div.md-text > span.ng-binding {
                display: none;
            }
        }

        span.smtp-error {
            margin-bottom: variables.$default-spacing * 0.5;
            color: #d50000;

            a {
                margin: auto;
                text-decoration: underline;
            }
        }

        input.warning {
            color: variables.$warning-color;
        }

        input,
        md-select {
            padding: 0;
            margin: auto 0;

            @include utils.font-size(14);
        }

        .md-select-value {
            padding: 0;
            min-height: 0;
        }

        .md-select-value > span:not(.md-select-icon) {
            @include utils.font-size(14);
        }

        .md-datepicker-input-container {
            border: none;
        }

        .campaign-input.invalid,
        .audience-section.invalid {
            &:not(.no-border) {
                border-bottom: 1px solid variables.$error-color;
            }

            .label {
                color: variables.$error-color;
            }

            input#subject {
                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: variables.$error-color;
                    font-weight: 400;
                    opacity: 1; /* Firefox */
                }
            }
        }

        .campaign-input {
            // border-bottom: 1px solid;
            // border-color: rgba(0, 0, 0, 0.12);

            padding: 0;

            &.no-border {
                border-bottom: none;
            }

            .label {
                color: rgb(0 0 0 / 38%);
                margin-right: (variables.$default-spacing * 0.5);
            }

            #subject {
                line-height: 16px;
                height: 16px;
                margin-top: 3px;
                border-width: 0 0 1px;
            }

            .md-errors-spacer {
                display: none;
            }

            md-datepicker.inline-datepicker {
                .md-button.md-icon-button {
                    margin: 0 10px 0 0;
                }

                .md-datepicker-triangle-button.md-button.md-icon-button {
                    height: 38px;
                    width: 38px;
                }

                .md-datepicker-triangle-button {
                    bottom: 1px;
                }

                .md-datepicker-expand-triangle {
                    border-left: 4.5px solid transparent;
                    border-right: 4.5px solid transparent;
                    border-top: 4.5px solid rgb(0 0 0 / 54%);
                }
            }
        }

        &.reply-input-container {
            margin-top: auto;
            margin-bottom: auto;

            .reply-checkbox {
                margin: 0;

                > .md-label {
                    line-height: 1; // Attempt to vertically align the label with the checkbox
                }
            }

            + md-icon {
                margin-left: math.div(variables.$default-spacing, 3);
            }
        }
    }

    .hidden-form-element {
        display: none;
    }

    #from-input {
        display: inherit;

        label {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 9px;
        }
    }

    md-select[disabled] .md-select-value {
        background-size: 0;
    }

    .drag-overlay {
        // Removed this again because it broke the file upload on email compose
        // pointer-events: none; // Needed in Chrome to prevent flickering when the drop zone contains child elements, see: https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
        position: absolute;
        top: 0;
        opacity: 0.7;
        width: 100%;
        height: 100%;
        background-color: variables.$drag-overlay-background-color;
        border: 3px solid variables.$drag-overlay-border-color;
        z-index: 2; // Get it above the toolbar

        .overlay-text {
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 40px;
            color: variables.$accent-color;
        }
    }
}

// data source indentation
.data-source-is-alias {
    padding-left: variables.$default-spacing * 1.5;

    &:before {
        content: "\21b3";
        margin-bottom: math.div(variables.$default-spacing, 6);
        margin-right: variables.$default-spacing * 0.25;
    }
}
