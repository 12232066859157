@use "sass/core/variables";
@use "sass/core/utils";

$middle-max-width: 350px;
$button-min-width: 88px; // reset to md value
$button-spacing-column: 18px;
$button-spacing-row: 12px;
$middle-text-content-seperation: 16px;

#signup,
#login,
#checkout,
#plans,
#credits {
    h1 {
        font-family: Raleway, "Trebuchet MS", sans-serif;
        font-weight: 500;
    }

    md-toolbar .md-toolbar-tools {
        font-size: inherit; // reset font size to normal, since we override it
    }

    #left-panel {
        width: 500px;
        background-color: variables.$dark-blue-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #right-panel {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            padding: 20px 35px;

            footer {
                @include utils.font-size(14);
            }
        }

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            padding: 4px 16px;

            footer {
                @include utils.font-size(12);
            }
        }

        header {
            font-size: variables.$font-size-16 + px; // no mixin since this should stay the same

            > span {
                margin-right: 8px;
                margin-left: auto;
                line-height: 36px;
            }

            .md-button {
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .middle:not(.larger-prospects) {
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                max-width: 1000px;
            }
        }

        .middle {
            @media screen and (max-width: variables.$layout-breakpoint-xs) {
                md-input-container,
                .md-button:not(.discount-button, .skip-button) {
                    width: 100%;
                }
            }

            .button-info {
                margin-top: 4px;
                font-size: 12px;

                &.error {
                    color: variables.$error-color;
                    margin-top: 0;
                }

                &.non-company-email {
                    margin-bottom: 0;
                }
            }

            .extras {
                margin-top: 26px;
                padding: 2px; // input container in form also has this

                a {
                    margin-bottom: 2px; // move lines a bit further from each other
                }
            }

            md-input-container:not(.question-container) {
                width: $middle-max-width - $button-min-width - 19px; // 19 is to account for margins etc

                @media screen and (max-width: variables.$layout-breakpoint-xs) {
                    width: 100%;
                }
            }

            form:not(.discount-form, .questionsForm) {
                padding: 0; // reset
                &:not(.expand) {
                    max-width: 270px;
                }

                @media screen and (max-width: variables.$layout-breakpoint-xs) {
                    max-width: 100%;
                }

                // both login and signup
                button {
                    min-width: $button-min-width; // reset to material since it looks better

                    &:not([disabled]) {
                        background-color: variables.$blue-color;
                        color: variables.$white-color;
                    }
                }

                > div#recaptcha-branding {
                    margin: 0;
                    height: 58px; // height equal to the login inputs
                }

                // login
                > div {
                    margin-top: 8px;

                    @media screen and (min-width: variables.$layout-breakpoint-sm) {
                        md-input-container:first-of-type {
                            padding-right: 60px;
                        }
                    }
                }

                md-input-container {
                    width: 270px;
                }
            }
        }
    }

    .error-message {
        color: variables.$error-color;
    }
}

.login-content .form-section {
    margin-bottom: 0;
}

#signup,
#login {
    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        md-input-container:not(.question-container) {
            margin: 0;
        }
    }

    #right-panel .middle {
        .signup-textblock {
            @include utils.font-size(16);
        }

        .line-container {
            margin: $button-spacing-column 0;

            .line-text {
                margin: 0 $button-spacing-row;
            }

            .line {
                margin: $button-spacing-column 0;
                border-top: 1px solid variables.$salesflare-color;
            }
        }

        .buttons {
            margin-top: $middle-text-content-seperation;
            flex-direction: column;

            .button {
                text-align: left;
                font-size: 14px;
                width: 270px;
                padding: 0;
                max-height: 40px;
                text-transform: none;

                &:not([disabled]) {
                    color: variables.$white-color;
                }

                @media screen and (min-width: variables.$layout-breakpoint-xs) {
                    max-width: $middle-max-width;
                }

                // put spacing in between when in row mode
                @media screen and (min-width: variables.$layout-breakpoint-md) {
                    & + .button {
                        margin-top: $button-spacing-column;
                    }
                }

                // put spacing in between when in column mode
                @media screen and (max-width: variables.$layout-breakpoint-md) {
                    & + .button {
                        margin-top: $button-spacing-column;
                    }
                }

                md-icon {
                    margin-right: 5px;
                    line-height: 100%; // make sure icon aligns decently
                }
            }

            .button-gmail:not([disabled]) {
                background-color: #4285f4;
            }

            .button-microsoft:not([disabled]) {
                background-color: #2f2f2f;
            }

            .button-outlook:not([disabled]) {
                background-color: #0072c6;
            }

            .button-imap:not([disabled]) {
                background-color: variables.$blue-color;
            }

            // Some weird stuff to make things work with images in buttons
            div.button-text {
                display: inline-block;
                vertical-align: top;
                font-weight: 600;
                margin-top: 2px;
                margin-left: 16px;
            }
        }

        .signup-imap-connect-form {
            margin-top: $middle-text-content-seperation;

            &:not(.expand) {
                @media screen and (max-width: variables.$layout-breakpoint-xs) {
                    max-width: 100%;
                }
            }
        }
    }
}
